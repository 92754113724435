import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toggleTheme } from "./Features/CommonSlice";
import {
  createDeviceData,
  getLoginAuditLog,
  getSubscriptionById,
  getUserDeviceIds,
  updateUserDeviceId,
} from "./services/paymentService";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { supabase } from ".";
import { getFirebaseToken } from "./firebase";
import { Button } from "reactstrap";
import { geoLocation } from "./services/sightdreamService";

export const toastobj = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export const giftcarddetails = {
  sendto: "",
  sendemail: "",
  message: "",
  comment: "",
  sender: "",
  deliverdate: "",
  amount: 0,
  selamount: 0,
  cardholdername: "",
  cardmonth: "",
  cardyear: "",
  cardnumber: "",
  cvv: "",
  paymentemail: "",
  discount: 0,
  discountcode: "",
  isdiscountcode: false,
};

export const card_number = (num) => {
  if (!num) return "";

  let trimmed = num.replace(/\s+/g, "");
  if (trimmed.length > 20) {
    trimmed = trimmed.substr(0, 20);
  }

  let numbers = [];
  for (let i = 0; i < trimmed.length; i += 4) {
    numbers.push(trimmed.substr(i, 4));
  }
  return numbers.join(" ");
};

export const getcardnumberforapi = (num) => {
  return num.split(" ").join("");
};

export const analyzeslidermark = [
  { value: 0, label: "Insignificant" },
  { value: 25, label: "" },
  { value: 50, label: "Neutral" },
  { value: 75, label: "" },
  { value: 100, label: "Very Important" },
];
export const analyzeslidervived = [
  { value: 0, label: "Really Hazy" },
  { value: 25, label: "" },
  { value: 50, label: "Neutral" },
  { value: 75, label: "" },
  { value: 100, label: "Very Vivid" },
];

export const trimvalue = (val) => {
  if (val) {
    let trimval = val.replace(/[\r\n]{2,}/g, "\n");
    let arr = trimval.split("\n");
    return arr;
  } else {
    return [];
  }
};

export const RoundButton = ({ type, onClick, filled }) => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  let buttonClasses = filled
    ? `${type}FilledButton${darkMode ? "-dark" : "-light"} roundButton${
        darkMode ? "-dark" : "-light"
      }`
    : `${type}OutlinedButton${darkMode ? "-dark" : "-light"} roundButton${
        darkMode ? "-dark" : "-light"
      }`;
  return (
    <button className={buttonClasses} onClick={onClick}>
      <FontAwesomeIcon
        icon={type === "forward" ? faChevronRight : faChevronLeft}
      />
    </button>
  );
};
export const RoundButtonDark = ({ type, onClick, filled, propClass = "" }) => {
  // const darkMode = useSelector((state) => state.theme.darkMode);
  let buttonClasses = filled
    ? `${type}FilledButton-dark roundButton-dark ${propClass}`
    : `${type}OutlinedButton-dark roundButton-dark ${propClass} `;
  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      style={{ color: "white" }}
    >
      {type === "forward" ? ">" : "<"}
    </button>
  );
};
export const RoundButtonLight = ({ type, onClick, filled }) => {
  // const darkMode = useSelector((state) => state.theme.darkMode);
  let buttonClasses = filled
    ? `${type}FilledButton-dark roundButton-light `
    : `${type}OutlinedButton-dark roundButton-light `;
  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      style={{ border: "1px solid #8850FF" }}
    >
      {type === "forward" ? (
        <FontAwesomeIcon icon={faChevronRight} />
      ) : (
        <FontAwesomeIcon icon={faChevronLeft} />
      )}
    </button>
  );
};
export const BoxSmall = ({
  imageSrc,
  singleLineText,
  multiLineText,
  type,
  key,
  toggleViewDetails,
}) => {
  return (
    <div
      className={`box-small flexColStart ${
        type === "arc" ? "box-small-arc" : ""
      } ${window.innerWidth < 600 ? "rg-05" : "rg-1"}`}
    >
      <img src={imageSrc} alt="Box" className="box-image" />
      <p
        className={`single-line-text font-32 ${type == "arc" ? "font-22" : ""}`}
        style={type === "arc" ? { color: "#000" } : {}}
      >
        {singleLineText}
      </p>
      {type == "arc" ? (
        trimvalue(multiLineText).map((item, index) => (
          <p
            key={index}
            className="multi-line-text color-grey mb-0"
            dangerouslySetInnerHTML={{ __html: item }}
          />
        ))
      ) : (
        <p className="multi-line-text color-grey">{multiLineText}</p>
      )}
      {type == "arc" && (
        <div className="w-100 text-center">
          <Button
            onClick={() => toggleViewDetails(key)}
            color="primary"
            className="btn-outlined"
          >
            View Details
          </Button>
        </div>
      )}
    </div>
  );
};
export const BoxSmallArc = ({
  imageSrc,
  singleLineText,
  multiLineText,
  key,
  toggleViewDetails,
}) => {
  return (
    <div
      className={`box-small flexColStart box-small-arc
       ${window.innerWidth < 600 ? "rg-05" : "rg-1"}`}
    >
      <img src={imageSrc} alt="Box" className="box-image" />
      <p className={`single-line-text font-22`} style={{ color: "#000" }}>
        {singleLineText}
      </p>
      {trimvalue(multiLineText).map((item, index) => (
        <p
          key={index}
          className="multi-line-text color-grey mb-0"
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ))}

      <div
        className="w-100 flexCenter mt-4"
        style={{ height: "100%", alignItems: "flex-end" }}
      >
        <Button
          onClick={() => toggleViewDetails(key)}
          color="primary"
          className="btn-outlined arc-view-detail-btn"
        >
          View Details
        </Button>
      </div>
    </div>
  );
};
export const BoxLarge = ({ titleText, subTitleText, multiLineText }) => {
  return (
    <div
      className={`box-large flexColStart ${
        window.innerWidth <= 600 ? "rg-05" : "rg-1"
      }`}
    >
      <div>
        <div className="title-text">{titleText}</div>
        <div className="sub-title-text color-grey">{subTitleText}</div>
      </div>
      <p
        className="multi-line-text "
        style={{ color: "var(--text-color-black)" }}
      >
        {multiLineText}
      </p>
    </div>
  );
};

export const ButtonSolid = ({ text, onClick, width }) => {
  return (
    <button className="solid-button" onClick={onClick} style={{ width: width }}>
      {text}
    </button>
  );
};

const fontOptions2 = [
  {
    id: 1,
    label: "Red Hat Display ",
    family: "Red Hat Display, sans-serif",
    weight: "400",
    style: "",
  },
  {
    id: 2,
    label: "Red Hat Display ",
    family: "Red Hat Display, sans-serif",
    weight: "700",
    style: "",
  },
  {
    id: 3,
    label: "Red Hat Display",
    family: "Red Hat Display, sans-serif",
    weight: "400",
    style: "italic",
  },
  {
    id: 4,
    label: "monospace",
    family: "Space Mono, monospace",
    weight: "400",
    style: "",
  },
  {
    id: 5,
    label: "Red Hat Display ",
    family: "Red Hat Display, sans-serif",
    weight: "700",
    style: "italic",
  },
  {
    id: 6,
    label: "Parisienne",
    family: "Parisienne, cursive",
    weight: "400",
    style: "",
  },
  {
    id: 7,
    label: "Cormorant Garamond",
    family: "Cormorant Garamond, serif",
    weight: "400",
    style: "",
  },
  {
    id: 8,
    label: "Cormorant Garamond",
    family: "Cormorant Garamond, serif",
    weight: "400",
    style: "italic",
  },
  {
    id: 9,
    label: "Red Hat Display Light",
    family: "Red Hat Display, sans-serif",
    weight: "400",
    style: "light",
  },
];

export const FontMenu = ({ selectedFont, onSelectFont }) => {
  return (
    <div className="font-menu">
      {fontOptions2.map((font, index) => (
        <div
          key={index}
          className={`font-option ${
            selectedFont.id === font.id ? "selected" : ""
          }`}
          style={{
            fontFamily: font.family,
            fontWeight: font.weight,
            fontStyle: font.style || "normal",
          }}
          onClick={() => {
            selectedFont = font;
            onSelectFont(font);
          }}
        >
          Aa
        </div>
      ))}
    </div>
  );
};
export const DiarySliderSettings = {
  slidesPerView: 2,
  spaceBetween: 20,
  breakpoints: {
    480: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 2,
    },
    750: {
      slidesPerView: 3,
    },
    1100: {
      slidesPerView: 3,
    },
  },
};
export const PricingPlanCarouselSetting = {
  spaceBetween: 20,
  breakpoints: {
    480: {
      slidesPerView: 1.1,
    },
    600: {
      slidesPerView: 2.1,
    },
    750: {
      slidesPerView: 2.1,
    },
    1100: {
      slidesPerView: 3.1,
    },
  },
};
export const PricingPlanCarouselSetting2 = {
  spaceBetween: 20,
  breakpoints: {
    480: {
      slidesPerView: 1.1,
    },
    600: {
      slidesPerView: 2.1,
    },
    750: {
      slidesPerView: 2.1,
    },
    1100: {
      slidesPerView: 3.1,
    },
  },
};
export const TrendingDreamSlider = {
  spaceBetween: 10,
  breakpoints: {
    280: {
      slidesPerView: 1.4,
    },
    480: {
      slidesPerView: 2.2,
    },
    750: {
      slidesPerView: 2.2,
    },
    830: {
      slidesPerView: 3.2,
    },
    1030: {
      slidesPerView: 3.2,
    },
    1100: {
      slidesPerView: 4.2,
    },
  },
};

export const ArcTestCarousel = {
  spaceBetween: 20,
  breakpoints: {
    480: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 1,
    },
    750: {
      slidesPerView: 1,
    },
    1100: {
      slidesPerView: 4,
    },
  },
};
export const RealReviewsSetting = {
  spaceBetween: 20,
  breakpoints: {
    480: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 2,
    },
    750: {
      slidesPerView: 2,
    },
    1100: {
      slidesPerView: 2,
    },
  },
};

export const NewFormatDateToCustomString = (date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}+00`;

  return formattedDate;
};
export const stripeAppearance = {
  theme: "night",
  labels: "floating",
  variables: {
    colorPrimary: "#8850ff",
    colorBackground: "#070d36",
    colorText: "#ffffff",
    colorDanger: "#df1b41",
    fontFamily: "Red Hat Display, sans-serif",
  },
  rules: {
    /* '.TabLabel--selected': {
			color: '#ffffff',
		},
		'.TabIcon--selected': {
			fill: '#ffffff',
		},
		'.Input': {
			backgroundColor: 'rgba(255,255,255,0.05)',
			border: 'none',
			boxShadow: 'none',
		},
		'.Input:disabled': {
			backgroundColor: 'rgba(255,255,255,0.05)',
			border: 'none',
			boxShadow: 'none',
		},
		'.Tab': {
			backgroundColor: 'rgba(255,255,255,0.05)',
			border: 'none',
			boxShadow: 'none',
		},
		'.Dropdown': {
			backgroundColor: 'rgba(255,255,255,0.05)',
			border: 'none',
			boxShadow: 'none',
		},

		'.Block': {
			backgroundColor: 'rgba(255,255,255,0.05)',
			border: 'none',
			boxShadow: 'none',
		}, */
    ".CheckboxInput": {
      border: "1px solid #8850ff ",
    },
  },
};

export const showMessagePayment = (message, type) => {
  if (type === "success") {
    toast.success(message, toastobj);
  } else {
    toast.error(message, toastobj);
  }
};

export const alwaysTruePaths = [
  "/auth/addjournalentry",
  "/auth/addjournalgratitude",
  "/auth/myjournal",
  "/auth/journalsummary",
  "/auth/journalgoodhabit",
  "/auth/journalgoals",
  "/auth/journalsetting",
  "/seventhjournal",
  "/auth/journalresources",
];

export const alwaysFalsePaths = [
  // The paths for which darkMode to always be false
  "/seventharc",
  "/auth/startmaintest",
  "/auth/arcana_persona",
];

export const CheckPathTheme = () => {
  const pathAfterAuth = new URL(window.location.href).pathname;
  let theme = {
    seventhsight: true,
    seventharc: false,
    seventhjournal: false,
  };
  let darkMode;
  let storedTheme;
  const storedThemeStr = localStorage.getItem("seventh_journal_theme");

  if (storedThemeStr) {
    try {
      storedTheme = JSON.parse(storedThemeStr);
    } catch (e) {
      // console.error("Error parsing JSON from localStorage", e);
      storedTheme = theme;
    }
  }

  if (alwaysTruePaths.some((path) => pathAfterAuth.includes(path))) {
    let temp =
      storedTheme && storedTheme.seventhjournal !== undefined
        ? storedTheme.seventhjournal
        : false;

    darkMode = temp;
  } else if (alwaysFalsePaths.some((path) => pathAfterAuth.includes(path))) {
    darkMode = false;
  } else {
    darkMode = true;
  }

  return { darkMode, theme: storedTheme };
  // dispatch(toggleTheme(storedTheme));
};

// This is used to identify header
export const checkPathType = () => {
  const pathAfterAuth = new URL(window.location.href).pathname;
  if (alwaysTruePaths.some((path) => pathAfterAuth.includes(path))) {
    return 1; // for SeventhJOURNAL
  } else if (alwaysFalsePaths.some((path) => pathAfterAuth.includes(path))) {
    return 2; // for SeventhArc
  } else {
    return 3; // for SeventhSIGHT
  }
};

export const makeThreeAttempts = async (sub_id, user_id, token) => {
  for (let i = 0; i < 3; i++) {
    try {
      const response = await getSubscriptionById(sub_id, user_id, token);

      // Check if the response status is success
      if (response.status === 200) {
        return response;
      } else {
        // If the status is not success, wait for 5 seconds before the next attempt
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } catch (error) {
      // If an error occurs, wait for 5 seconds before the next attempt
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }

  // If all three attempts fail, return false
  return false;
};

export const siteMapping = {
  1: "seventhjournal",
  2: "seventharc",
  3: "seventhsight",
};
export const calculateMaxHeight = (reviewList) => {
  let max = 0;

  reviewList.forEach((item) => {
    const container = document.createElement("div");
    container.innerHTML = `<div class="item hp-review-box">
                              <div class="hp-review-title">${item.name}</div>
                              <div class="hp-review-subtitle">${item?.role}</div>
                              <p>${item?.body}</p>
                            </div>`;

    document.body.appendChild(container);
    const height = container.offsetHeight;
    document.body.removeChild(container);

    if (height > max) {
      max = height;
    }
  });

  return max;
};
export const calculateParagraphHeight = (paragraph) => {
  let max = 0;
  const parentContainer = document.createElement("div");
  parentContainer.className = "col-md-6";
  const container = document.createElement("div");
  trimvalue(paragraph).forEach((item) => {
    const paragraphContainer = document.createElement("p");
    paragraphContainer.className = "mt-2 capitalfirstword";
    paragraphContainer.textContent = item;

    container.appendChild(paragraphContainer);

    document.body.appendChild(container);
  });
  // console.log(container);
  parentContainer.appendChild(container);

  // Append parent container to body
  document.body.appendChild(parentContainer);
  const height = container.offsetHeight;
  document.body.removeChild(parentContainer);

  if (height > max) {
    max = height;
  }
  // console.log(max);
  return max;
};
const emojiData = {
  neutral:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-neutral.png?t=2024-01-09T17%3A09%3A47.489Z",
  happy:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-happy.png",
  excited:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-excited.png?t=2024-01-09T15%3A50%3A39.506Z",
  courageous:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-courageous.png?t=2024-01-09T15%3A40%3A42.839Z",
  anxious:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-anxious.png?t=2024-01-09T15%3A33%3A23.125Z",
  scared:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-scared.png?t=2024-01-09T17%3A38%3A42.333Z",
  rejected:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-rejected.png?t=2024-01-09T17%3A23%3A13.129Z",
  angry:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-angry.png?t=2024-01-09T15%3A25%3A43.779Z",
  frustrated:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-frustrated.png",
  sad: "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-sad.png?t=2024-01-09T17%3A29%3A35.687Z",
  guilty:
    "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-guilty.png",
};
export const CustomDot = ({ cx, cy, value, moodsData, index }) => {
  const dataPoint = value[1];
  let i = 0;
  // console.log(i++);
  const objectAtIndex = moodsData[index];
  const keysOfObjectAtIndex = Object.keys(objectAtIndex);

  const emojiImage = emojiData[objectAtIndex["emoji"]];

  return (
    <>
      <g>
        <line
          x1={cx}
          y1={cy + 12}
          x2={cx}
          y2={
            window.innerWidth > 600 ? cy + value[1] * 2.6 : cy + value[1] * 2.5
          }
          stroke="#fff"
          strokeWidth={2}
        />

        <text x={cx} y={cy} dy={0} textAnchor="middle"></text>
        <image
          x={cx - 12}
          y={cy - 12}
          width={24}
          height={24}
          href={emojiImage} // Access emoji using element.emoji
        />
      </g>
    </>
  );
};
export const CustomTick = ({ payload, x, y }) => (
  <g transform={`translate(${x},${y})`}>
    <text dy={24} textAnchor="middle" fill="#666">
      {payload.value}
    </text>
  </g>
);
/* const DreamTextComponent = ({ dreamText, highlightedWords }) => {
  // Define the common style for highlighted words
  const highlightStyle = { color: "#0CD5DC" };

  // console.log(dreamText);
  const splitPhraseIntoWords = (phrase) => phrase.split(/\s+/);

  //  const splitArray = (arr) => {
  //   return arr.map((item) => item.split(" "));
  // };

  // const subArrays = splitArray(highlightedWords);
  // console.log(subArrays); 

  const removePunctuation = (text) => text.replace(/[.,]/g, "");

  // Function to check if a word should be highlighted
  const shouldHighlight = (word) => {
    const lowerCaseWord = removePunctuation(word.toLowerCase());
    return highlightedWords.some((highlightedWord) => {
      const lowerCaseHighlightedWords = splitPhraseIntoWords(
        removePunctuation(highlightedWord.toLowerCase())
      );
      return lowerCaseHighlightedWords.includes(lowerCaseWord);
    });
  };

  // Function to render the dream text with highlighted words
  const renderDreamText = () => {
    const paragraphs = dreamText.split(/\n/);

    return paragraphs.map((paragraph, paragraphIndex) => {
      const words = paragraph.split(/\s+/);
      return (
        <p key={paragraphIndex}>
          {words.map((word, wordIndex) => (
            <span
              key={wordIndex}
              style={shouldHighlight(word) ? highlightStyle : {}}
            >
              {word}{" "}
            </span>
          ))}
        </p>
      );
    });
  };

  return <div>{renderDreamText()}</div>;
};

export default DreamTextComponent; */

export const INACTIVE_USER = "InActive User.. Please contact support!!";
export const ACCOUNT_DELETE_REQUEST = "Account deleted successfully!";

export const dreamArcanaData = {
  odyssy_dreamer: {
    title: "Odyssey Dreamer",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/odyssey_card_image.png?t=2024-04-16T09%3A56%3A46.859Z",
    text_one:
      "Odyssey Dreamers experience highly vivid and imaginative dreams, often filled with fantastical adventures and rich symbolism. These individuals often exhibit traits of creativity, openness to new experiences, and a deep curiosity about the world around them.",
    text_two:
      "The Oracle Overture Archetype encompasses highly prophetic, intuitive, and fifth-dimensional dreamers who possess an innate ability to tap into deeper realms of consciousness. Their dreams are vividly symbolic and serve as channels for profound insights into the past, present, and future.\nThe Oracle Overture Archetype represents individuals who possess a rare and extraordinary gift for accessing higher realms of consciousness through their dreams. These dreamers are highly intuitive and prophetic, receiving profound insights and revelations that transcend the limitations of time and space. Oracle Overtures navigate the dream world with a heightened sense of awareness, tapping into the collective unconscious to unveil hidden truths and unlock the mysteries of the universe. Their dreams are vividly symbolic and serve as channels for divine wisdom and guidance, offering glimpses into the past, present, and future. Through their prophetic dream experiences, Oracle Overtures serve as conduits for higher knowledge and enlightenment, guiding themselves and others on a journey of spiritual awakening and self-discovery.",
    class_name: "dreamology-arcana-pg1",
  },
  lucid_voyager: {
    title: "Lucid Voyager",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/lucid_card_image.png?t=2024-04-16T10%3A59%3A55.594Z",
    text_one:
      "Lucid Voyager Archetypes frequently experience lucid dreams, demonstrating a heightened sense of self-awareness and often displaying traits of conscientiousness, problem-solving, and creativity. Their ability to control dream narratives suggests a deep connection to their inner psyche and a proactive approach to exploring their subconscious mind.",
    text_two:
      "Lucid Voyager Archetypes frequently experience lucid dreams, demonstrating a heightened sense of self-awareness and often displaying traits of conscientiousness, problem-solving, and creativity. Their ability to control dream narratives suggests a deep connection to their inner psyche and a proactive approach to exploring their subconscious mind.\nThe Lucid Voyager Archetype represents individuals who possess the remarkable ability to navigate their dreams with conscious awareness. These dreamers find themselves in a state of lucidity, where they are fully aware that they are dreaming and can often exert control over the dream narrative. This heightened self-awareness hints at a strong sense of introspection and mindfulness in waking life, allowing Lucid Voyagers to delve deep into their subconscious mind and explore their innermost thoughts and desires. Their propensity for lucid dreaming may also indicate traits of conscientiousness, as they exhibit a proactive approach to problem-solving and creativity, both within their dreams and in their daily lives. Lucid Voyagers view their dreams as a canvas for self-exploration and personal growth, using their lucid dreaming abilities to unlock the mysteries of their psyche and uncover hidden truths about themselves and the world around them.",
    class_name: "dreamology-arcana-pg2",
  },
  dreadnought: {
    title: "Dreadnought",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/dreadnought_card_image.png",
    text_one:
      "Individuals who frequently experience anxiety-driven dreams, such as being chased or feeling unprepared, may fall into the Dreadnought Archetype. These dreams often reflect underlying neuroticism and a predisposition toward anxiety and stress in waking life, highlighting the individual's need for emotional regulation and coping strategies.",
    text_two:
      "Individuals who frequently experience anxiety-driven dreams, such as being chased or feeling unprepared, may fall into the Dreadnought Archetype. These dreams often reflect underlying neuroticism and a predisposition toward anxiety and stress in waking life, highlighting the individual's need for emotional regulation and coping strategies.\nThe Dreadnought Archetype is characterized by individuals who regularly encounter anxiety-driven dreams that evoke feelings of fear, stress, and apprehension. These dreams often manifest as scenarios where the dreamer finds themselves pursued or facing imminent danger, mirroring the individual's underlying neurotic tendencies and propensity for anxiety in waking life. Dreadnoughts may struggle with emotional regulation and may find themselves overwhelmed by stressors and uncertainties, both in their dreams and in their daily lives. Their recurring anxiety dreams serve as a reflection of their inner turmoil and the need for effective coping mechanisms to navigate the challenges they face. Through self-awareness and the implementation of stress-reduction techniques, Dreadnoughts can work towards achieving a sense of inner peace and resilience, ultimately reclaiming control over their dreams and their waking reality.",
    class_name: "dreamology-arcana-pg3",
  },
  echo: {
    title: "Echo",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/echo_card_image.png?t=2024-04-16T11%3A03%3A20.820Z",
    text_one:
      "Echo Archetypes experience recurring dreams, often focusing on unresolved issues or conflicts, suggesting a strong inclination toward introspection and emotional processing. These individuals may be deeply reflective, engaging in inner dialogue to confront and resolve deep-seated emotional experiences or trauma.",
    text_two:
      "Echo Archetypes experience recurring dreams, often focusing on unresolved issues or conflicts, suggesting a strong inclination toward introspection and emotional processing. These individuals may be deeply reflective, engaging in inner dialogue to confront and resolve deep-seated emotional experiences or trauma.\nThe Echo Archetype represents individuals who frequently encounter recurring dreams, echoing unresolved issues or conflicts from their waking lives. These dreams serve as a mirror reflecting the dreamer's innermost thoughts, emotions, and experiences, indicating a profound inclination toward introspection and self-exploration. Echoes delve into the depths of their subconscious mind, grappling with unresolved traumas, anxieties, or conflicts that continue to linger beneath the surface. Through their recurring dream experiences, Echoes embark on a journey of emotional processing and healing, confronting their inner demons and seeking resolution to long-standing issues. These individuals may possess a heightened sense of empathy and intuition, as they navigate the intricate landscapes of their dreams with a deep understanding of their own emotional complexities. By embracing their recurring dreams as catalysts for growth and self-discovery, Echoes can embark on a path towards inner peace and wholeness, transcending the echoes of the past and forging a brighter future.",
    class_name: "dreamology-arcana-pg4",
  },
  quest: {
    title: "Quest",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/quest_card_image.png?t=2024-04-16T11%3A05%3A29.406Z",
    text_one:
      "The Quest Archetype embodies dreamers who frequently embark on adventurous journeys and explore unknown realms during their dream experiences. These dreams often reflect a strong sense of extraversion and openness, as individuals seek excitement, adventure, and new experiences in both their dreamscapes and waking lives.",
    text_two:
      "The Quest Archetype embodies dreamers who frequently embark on adventurous journeys and explore unknown realms during their dream experiences. These dreams often reflect a strong sense of extraversion and openness, as individuals seek excitement, adventure, and new experiences in both their dreamscapes and waking lives.\nWithin the realm of dreams, the Quest Archetype represents individuals who thrive on adventure and exploration. These dreamers frequently find themselves embarking on epic quests, traversing mysterious landscapes, and encountering fantastical beings along the way. Their dreams are filled with excitement, wonder, and the thrill of discovery, mirroring their innate love for adventure and novelty in waking life. Questers possess a boundless curiosity and a desire to push the boundaries of their own experiences, embracing the unknown with open arms and an adventurous spirit. Through their dream adventures, they uncover hidden truths about themselves and the world around them, gaining insights that fuel their thirst for exploration and self-discovery.",
    class_name: "dreamology-arcana-pg5",
  },
  social_sojourner: {
    title: "Social Sojourner",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/social_card_image.png?t=2024-04-16T11%3A06%3A16.016Z",
    text_one:
      "Individuals who frequently dream about social interactions, gatherings, or relationships fall into the Social Sojourner Archetype. These dreams offer insights into the dreamer's interpersonal dynamics, reflecting aspects of agreeableness and extraversion as they navigate the complexities of human relationships in their dreamscapes.",
    text_two:
      "Individuals who frequently dream about social interactions, gatherings, or relationships fall into the Social Sojourner Archetype. These dreams offer insights into the dreamer's interpersonal dynamics, reflecting aspects of agreeableness and extraversion as they navigate the complexities of human relationships in their dreamscapes.\nThe Social Sojourner Archetype embodies dreamers who find themselves immersed in rich social interactions and dynamic relationships within their dreams. These dreamers frequently encounter scenarios involving gatherings, conversations, and meaningful connections with others, reflecting their deep-seated need for social engagement and connection. Social Sojourners thrive in the realm of dreams, where they explore the intricacies of human relationships and dynamics, gaining valuable insights into their own interpersonal behaviors and interactions. Through their dream experiences, they navigate the complexities of social interaction with grace and insight, forging meaningful connections and fostering a sense of belonging in both their dreamscapes and waking lives.",
    class_name: "dreamology-arcana-pg6",
  },
  nightshade_detective: {
    title: "Nightshade Detective",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/nightshade_card_image.png?t=2024-04-16T11%3A07%3A18.655Z",
    text_one:
      "The Nightshade Detective Archetype embodies individuals who often dream about solving problems, unraveling mysteries, and inventing new ideas. These dreams reflect a practical, analytical approach to life challenges and a high level of openness to new ideas and intellectual pursuits.",
    text_two:
      "The Nightshade Detective Archetype embodies individuals who often dream about solving problems, unraveling mysteries, and inventing new ideas. These dreams reflect a practical, analytical approach to life challenges and a high level of openness to new ideas and intellectual pursuits.\nWithin the realm of dreams, the Nightshade Detective Archetype represents individuals who possess a keen intellect and a natural inclination for problem-solving. These dreamers frequently find themselves immersed in scenarios where they engage in detective work, uncovering clues, and piecing together mysteries with precision and ingenuity. Nightshade Detectives approach challenges with a practical and analytical mindset, employing logic and reason to navigate the complexities of their dreamscapes. Their dreams often involve inventing new solutions and exploring innovative ideas, reflecting their openness to new possibilities and intellectual pursuits. Through their dream experiences, Nightshade Detectives gain valuable insights into their own cognitive abilities and problem-solving skills, honing their analytical prowess and embracing the thrill of intellectual discovery.",
    class_name: "dreamology-arcana-pg7",
  },
  terror_tracker: {
    title: "Terror Tracker",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/terror_card_image.png?t=2024-04-16T11%3A08%3A27.805Z",
    text_one:
      "Individuals who frequently experience nightmares or disturbing dreams fall into the Terror Tracker Archetype. These dreams may be linked with high levels of stress, anxiety, or past trauma, reflecting aspects of neuroticism and unresolved emotional distress.",
    text_two:
      "Individuals who frequently experience nightmares or disturbing dreams fall into the Terror Tracker Archetype. These dreams may be linked with high levels of stress, anxiety, or past trauma, reflecting aspects of neuroticism and unresolved emotional distress.\nThe Terror Tracker Archetype embodies dreamers who grapple with frequent nightmares and disturbing dream experiences. These dreams are often characterized by feelings of fear, anxiety, and intense emotional distress, mirroring the dreamer's inner turmoil and psychological struggles. Terror Trackers may find themselves haunted by recurring nightmares or unsettling dream scenarios, reflecting their unresolved fears, anxieties, or past traumas. These dreams serve as a manifestation of the dreamer's innermost fears and anxieties, highlighting the need for emotional healing and self-care. Through introspection and self-awareness, Terror Trackers can begin to unravel the underlying causes of their nightmares and work towards resolving their emotional distress, ultimately finding peace and serenity within their dreamscapes and waking lives.",
    class_name: "dreamology-arcana-pg8",
  },
  dream_drone: {
    title: "Dream Drone",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/dreamdrone_card_image.png?t=2024-04-16T11%3A09%3A21.145Z",
    text_one:
      "Dream Drones experience controlled and monotonous dreams that closely resemble their daily life. These dreams reflect traits of conscientiousness, indicating a preference for order, planning, and control over spontaneity in both dreams and waking life.",
    text_two:
      "Dream Drones experience controlled and monotonous dreams that closely resemble their daily life. These dreams reflect traits of conscientiousness, indicating a preference for order, planning, and control over spontaneity in both dreams and waking life.\nThe Dream Drone Archetype embodies individuals whose dreams are characterized by routine and predictability, mirroring the structured nature of their daily lives. These dreamers often find themselves in mundane scenarios that closely resemble their waking reality, with little deviation or excitement. Dream Drones prioritize order and control, seeking stability and predictability in both their dreams and waking experiences. Their dreams serve as reflections of their conscientious nature, highlighting their preference for routine and adherence to established patterns. Through their controlled dream experiences, Dream Drones gain a sense of comfort and security, finding solace in the familiarity of their dreamscapes and the structured nature of their subconscious mind.",
    class_name: "dreamology-arcana-pg9",
  },
  symbolic_sorcerer: {
    title: "Symbolic Sorcerer",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/symbolic_card_image.png?t=2024-04-16T11%3A11%3A02.209Z",
    text_one:
      "Symbolic Sorcerers experience dreams filled with symbolism and mysticism, reflecting a deep introspective nature and a value for intuition and inner wisdom. These dreamers exhibit traits of contemplation, seeking deeper meanings in life's experiences and embracing the mystical aspects of their subconscious mind.",
    text_two:
      "Symbolic Sorcerers experience dreams filled with symbolism and mysticism, reflecting a deep introspective nature and a value for intuition and inner wisdom. These dreamers exhibit traits of contemplation, seeking deeper meanings in life's experiences and embracing the mystical aspects of their subconscious mind.\nWithin the realm of dreams, the Symbolic Sorcerer Archetype represents individuals who possess a profound connection to the symbolic and mystical realms of consciousness. These dreamers frequently encounter dreams that are rich in symbolism and imbued with layers of meaning, reflecting their deep introspective nature and their reverence for intuition and inner wisdom. Symbolic Sorcerers are contemplative beings who delve into the depths of their subconscious mind, seeking profound insights and revelations about themselves and the world around them. Their dreams serve as portals to the mystical and symbolic dimensions of consciousness, offering glimpses into the hidden truths and deeper meanings that lie beneath the surface of reality. Through their dream experiences, Symbolic Sorcerers embrace the enigmatic and mystical aspects of their subconscious mind, unlocking the secrets of their innermost selves and gaining a deeper understanding of the universe.",
    class_name: "dreamology-arcana-pg10",
  },
  oracle_overture: {
    title: "Oracle Overture",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/oracle_card_image.png?t=2024-04-16T11%3A11%3A53.845Z",
    text_one:
      "The Oracle Overture Archetype encompasses highly prophetic, intuitive, and fifth-dimensional dreamers who possess an innate ability to tap into deeper realms of consciousness. Their dreams are vividly symbolic and serve as channels for profound insights into the past, present, and future.",
    text_two:
      "The Oracle Overture Archetype encompasses highly prophetic, intuitive, and fifth-dimensional dreamers who possess an innate ability to tap into deeper realms of consciousness. Their dreams are vividly symbolic and serve as channels for profound insights into the past, present, and future.\nThe Oracle Overture Archetype represents individuals who possess a rare and extraordinary gift for accessing higher realms of consciousness through their dreams. These dreamers are highly intuitive and prophetic, receiving profound insights and revelations that transcend the limitations of time and space. Oracle Overtures navigate the dream world with a heightened sense of awareness, tapping into the collective unconscious to unveil hidden truths and unlock the mysteries of the universe. Their dreams are vividly symbolic and serve as channels for divine wisdom and guidance, offering glimpses into the past, present, and future. Through their prophetic dream experiences, Oracle Overtures serve as conduits for higher knowledge and enlightenment, guiding themselves and others on a journey of spiritual awakening and self-discovery.",
    class_name: "dreamology-arcana-pg11",
  },
  soul_seeker: {
    title: "Soul Seeker",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/soulseeker_card_image.png?t=2024-04-16T11%3A12%3A21.827Z",
    text_one:
      "The Soul Seeker Archetype embodies dreamers with a profound spiritual connection, experiencing dreams that are deeply meaningful and spiritually significant. These dreams provide guidance, revelations, and encounters with spiritual beings or realms, reflecting the dreamer's journey of self-discovery and enlightenment.",
    text_two:
      "The Soul Seeker Archetype embodies dreamers with a profound spiritual connection, experiencing dreams that are deeply meaningful and spiritually significant. These dreams provide guidance, revelations, and encounters with spiritual beings or realms, reflecting the dreamer's journey of self-discovery and enlightenment.\nWithin the realm of dreams, the Soul Seeker Archetype represents individuals who embark on a profound spiritual journey through their dream experiences. These dreamers possess a deep spiritual connection and often find themselves immersed in dreams that are rich in symbolism and spiritual significance. Soul Seekers receive guidance, wisdom, and revelations from the spiritual realms, encountering celestial beings, ancient wisdom keepers, and divine messengers along the way. Their dreams serve as portals to higher consciousness, offering insights into the mysteries of the universe and guiding them on a path of self-discovery and enlightenment. Through their spiritual dream experiences, Soul Seekers forge a deeper connection to their innermost selves and the spiritual dimensions of reality, finding purpose, meaning, and fulfillment in their dreamscapes and waking lives.",
    class_name: "dreamology-arcana-pg12",
  },
  dream_acrobat: {
    title: "Dream Acrobat",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/dreamacrobat_card_image.png?t=2024-04-16T11%3A14%3A44.112Z",
    text_one:
      "Dream Acrobats revel in magical and fantastical dream experiences, defying the laws of physics with whimsical adventures and playful spirit. They soar through the skies, breathe underwater, and engage in extraordinary escapades, embracing the surreal and enchanting realms of the dream world.",
    text_two:
      "Dream Acrobats revel in magical and fantastical dream experiences, defying the laws of physics with whimsical adventures and playful spirit. They soar through the skies, breathe underwater, and engage in extraordinary escapades, embracing the surreal and enchanting realms of the dream world.\nThe Dream Acrobat Archetype embodies dreamers who delight in the whimsical and fantastical aspects of dreaming. These dreamers possess an adventurous spirit and a playful imagination, eagerly exploring the boundless possibilities of the dream world. Dream Acrobats defy the constraints of reality, engaging in extraordinary feats of magic and wonder as they navigate through surreal dreamscapes. They soar through the skies on wings of imagination, dive into the depths of the ocean without fear, and embark on fantastical adventures with joy and exhilaration. Dream Acrobats embrace the surreal and enchanting realms of the dream world, finding liberation, creativity, and boundless exploration in their dreamscapes.",
    class_name: "dreamology-arcana-pg13",
  },
  jovial_dreamer: {
    title: "Jovial Dreamer",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/jovialdreamer_card_image.png?t=2024-04-16T11%3A15%3A43.517Z",
    text_one:
      "The Jovial Dreamer Archetype embodies the lighthearted and comedic side of dreaming, where dreamers find joy in comical and absurd dream scenarios. Encountering whimsical characters, engaging in hilarious antics, and navigating surreal situations, Jovial Dreamers bring laughter and amusement to their dream adventures.",
    text_two:
      "The Jovial Dreamer Archetype embodies the lighthearted and comedic side of dreaming, where dreamers find joy in comical and absurd dream scenarios. Encountering whimsical characters, engaging in hilarious antics, and navigating surreal situations, Jovial Dreamers bring laughter and amusement to their dream adventures.\nRepresenting the playful and lighthearted essence of dreaming, the Jovial Dreamer Archetype embodies individuals who find delight in the whimsical and absurd aspects of their dream experiences. These dreamers encounter a colorful cast of characters, engage in humorous antics, and navigate through surreal situations with laughter and amusement. Jovial Dreamers infuse their dreams with joy and merriment, embracing the comical and nonsensical elements of their subconscious mind. Through their dream adventures, they bring a sense of lightness and humor to their inner world, finding solace, entertainment, and laughter in the surreal landscapes of their dreams.",
    class_name: "dreamology-arcana-pg14",
  },
  romance_dreamer: {
    title: "Romance Dreamer",
    cardImage:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/ArcanaCardImg/romancedreamer_card_image.png?t=2024-04-16T11%3A16%3A32.304Z",
    text_one:
      "Romance Dreamers frequently immerse themselves in dreams filled with love, romance, and passionate encounters. These dreams reflect their longing for connection, emotional depth, and the pursuit of romantic ideals.",
    text_two:
      "Romance Dreamers frequently immerse themselves in dreams filled with love, romance, and passionate encounters. These dreams reflect their longing for connection, emotional depth, and the pursuit of romantic ideals.\nThe Romance Dreamer Archetype encapsulates individuals who frequently find themselves entangled in dreams woven with threads of love, romance, and emotional intimacy. These dreamers are drawn to the allure of heartfelt connections and the intoxicating dance of passion, often experiencing vivid dreams that depict romantic encounters, soulful embraces, and tender moments shared with loved ones or idealized partners. Romance Dreamers are guided by a deep longing for emotional fulfillment and meaningful relationships, seeking solace and joy in the dreamscapes where love knows no bounds. Through their romantic dream experiences, they explore the depths of their hearts, yearning for the ecstasy of love's embrace and the timeless allure of romance.",
    class_name: "dreamology-arcana-pg15",
  },
};

export const emojiDataNew = [
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-neutral.png?t=2024-01-09T17%3A09%3A47.489Z",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-neutral-white.png?t=2024-01-09T17%3A07%3A14.103Z",
    text: "neutral",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-happy.png",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-happy-white.png?t=2024-01-09T16%3A59%3A22.677Z",
    text: "happy",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-excited.png?t=2024-01-09T15%3A50%3A39.506Z",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-excited-white.png?t=2024-01-09T15%3A47%3A29.601Z",
    text: "excited",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-courageous.png?t=2024-01-09T15%3A40%3A42.839Z",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-courageous-white.png?t=2024-01-09T15%3A38%3A09.189Z",
    text: "courageous",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-anxious.png?t=2024-01-09T15%3A33%3A23.125Z",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-anxious-white.png",
    text: "anxious",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-scared.png?t=2024-01-09T17%3A38%3A42.333Z",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-scared-white.png?t=2024-01-09T17%3A36%3A17.389Z",
    text: "scared",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-rejected.png?t=2024-01-09T17%3A23%3A13.129Z",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-rejected-white.png",
    text: "rejected",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-angry.png?t=2024-01-09T15%3A25%3A43.779Z",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-angry-white.png?t=2024-01-09T15%3A21%3A37.788Z",
    text: "angry",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-frustrated.png",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-frustrated-white.png?t=2024-01-09T15%3A56%3A48.807Z",
    text: "frustrated",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-sad.png?t=2024-01-09T17%3A29%3A35.687Z",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-sad-white.png",
    text: "sad",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-guilty.png",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-guilty-white.png?t=2024-01-09T16%3A51%3A04.034Z",
    text: "guilty",
  },
  {
    image1:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-reject.png?t=2024-01-09T17%3A16%3A54.224Z",
    image2:
      "https://db.seventhsight.com/storage/v1/object/public/Website_Images/emoji-reject-white.png",
    text: "remove",
  },
];

export const handleClickClipboard = (textToCopy) => {
  const sanitizedText = textToCopy.replace(/\*\*/g, "");
  const sanitizedTextNoNewlines = sanitizedText.replace(/\n/g, "");
  navigator.clipboard
    .writeText(sanitizedTextNoNewlines)
    .then(() => {
      toast.success("Copied to clipboard", toastobj);
    })
    .catch((error) => {});
};

export const getFCMToken = async (newToken) => {
  const permissionResult = Notification.permission;

  // Check if permission is granted
  if (permissionResult !== "granted") {
    return; // No further processing if permission is not granted
  }

  try {
    const deviceId = await getFirebaseToken();

    if (!deviceId) {
      throw new Error("Device ID not found");
    }

    const { data: userData, error: sessionError } =
      await supabase.auth.getSession();

    if (sessionError) {
      throw sessionError;
    }

    const userid = userData.session.user.id;

    try {
      const response = await getUserDeviceIds(userid, newToken);

      // If the response is successful, update the existing user data
      const existingUserData = response.data.DeviceID;

      const payload = {
        SeventhSight_web: deviceId,
        updated_at: new Date(),
      };
      try {
        const updateResponse = await updateUserDeviceId(
          userid,
          existingUserData.id,
          payload,
          newToken
        );
      } catch (err) {
        // toast.error("Something went wrong!");
      }
    } catch (error) {
      // If the device ID is not found or another error occurs, insert a new row
      try {
        const requestData = {
          user_id: userid,
          SeventhSight_web: deviceId,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        };

        const createResponse = await createDeviceData(requestData, newToken);
      } catch (err) {
        // toast.error("Something went wrong!");
      }
    }
  } catch (error) {
    // Handle any other errors, such as session errors or device ID errors
    // toast.error("Something went wrong!");
  }
};

export const staticMessages = {
  KeyDiscoveries: {
    text: "Discover core findings of your dream analysis, highlighting the primary massages your subconscious is communicating.",
    showButton: true,
  },
  Reflections: {
    text: "Dive deeper into your dream’s meanings with thoughtful insides and questions designed to encourage personal reflection.",
    showButton: true,
  },
  unconscious_echoes: {
    text: "Uncover the profound narrative of your subconscious through analysis of recurring dream patterns across days, weeks, months and years. Discover the long term picture!\nOne of the most beneficial aspects of dream analysis.",
    showButton: true,
  },
  spiritual_religious: {
    text: "Explore the spiritual dimensions and religious symbols within your dreams, revealing deeper layers of meaning.",
    showButton: true,
  },
  love_relationships: {
    text: "Gain insights into your emotional world through the lens of your dreams, highlighting connections and areas for growth.",
    showButton: true,
  },
  work_career: {
    text: "Decode the messages your dreams are sending about your professional life, offering clues to aspirations and anxieties.",
    showButton: true,
  },
  DreamWorldGuidance: {
    text: "Receive direct guidance based on your dreams, suggesting paths of action for personal development and decision-making.",
    showButton: true,
  },
  PracticalAdvice: {
    text: "Embark on a journey of self-healing and growth with actionable steps derived from essence of your dreams.",
    showButton: true,
  },
  TailoredTools: {
    text: "Access personalized resources designed to facilitate your personal transformation and growth journey.",
    showButton: false,
  },
  RecommendedVideos: {
    text: "Watch curated videos that resonate with the themes of your dreams.",
    showButton: true,
  },
  RelatedBlogs: {
    text: "Read engaging blogs and articles that address the issues and themes revealed by your dream analysis.",
    showButton: false,
  },
  RecommendedBooks: {
    text: "Dive into a selection of books tailored to help you navigate and overcome the challenges identified in your dreams.",
    showButton: false,
  },
  RecommendedPodcasts: {
    text: "Listen to podcasts that complement your growth journey, carefully selected based on your dream analysis result.",
    showButton: true,
  },
  Details: {
    text: "Discover core findings of your dream analysis, highlighting the primary massages your subconscious is communicating.",
    showButton: true,
  },
};
export const FreemiumComponent = (props) => {
  const message = staticMessages[props.keyobject];

  const paragraphs = message?.text
    .split("\n")
    .map((paragraph, index) => <p key={index}>{paragraph}</p>);

  return (
    <div className="freemium_glass_eff">
      {!props?.journal && paragraphs}
      {message.showButton && (
        <button
          className={
            props?.journal ? "acct-btn-freemium-journal" : "acct-btn-guest"
          }
          onClick={() => {
            if (props?.journal || props?.seventhsight) {
              props.history("/create_subscription");
            } else {
              props.setsignupmodalhome(true);
            }
          }}
        >
          {props?.journal || props?.seventhsight
            ? "Unlock All Features"
            : "Create an Account for free to Unlock"}
        </button>
      )}
    </div>
  );
};

export const freemiumStyle = {
  position: "relative",
};

const DynamicComponent = ({ condition, freemiumProps, children }) => {
  const containerStyle = condition ? { position: "relative" } : {};

  return (
    <div style={containerStyle}>
      {condition && <FreemiumComponent {...freemiumProps} />}
      {children}
    </div>
  );
};

export default DynamicComponent;

export function calculateFreeTrialDays(date) {
  let endDate = new Date(date);
  let today = new Date();
  let timeDiff = endDate - today;
  let daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysLeft;
}

export function sendAuditLogs(
  user_id,
  email,
  textInfo,
  textAuditType,
  newToken
) {
  let ipAddress = "0.0.0.0";
  geoLocation()
    .then((jres) => {
      ipAddress = jres?.data?.ip;
      // localStorage.setItem("ip_address", jres?.data?.ip);
    })
    .catch((err) => {
      // console.log("376-->", err);
    })
    .finally(() => {
      const data = {
        user_id: user_id,
        email: email,
        ip_address: ipAddress,
        action: {
          info: textInfo,
        },
        created_at: new Date(),
        updated_at: new Date(),
        audit_type: textAuditType,
      };
      getLoginAuditLog(data)
        .then((res) => {})
        .catch((err) => {});
    });
}
